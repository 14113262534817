import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03540cc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-diagnostics-categories-list-form-create" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = { class: "form" }
const _hoisted_4 = { class: "el-form-other-action" }
const _hoisted_5 = { class: "el-form-other-action__item" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "el-form-other-action__item" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "el-form-other-action__item" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "el-form-add-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_Bottom = _resolveComponent("Bottom")!
  const _component_Top = _resolveComponent("Top")!
  const _component_CircleClose = _resolveComponent("CircleClose")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_CirclePlus = _resolveComponent("CirclePlus")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        class: "el-button--grey el-button--square",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Plus)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      title: "Dodaj",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form, {
            model: _ctx.formData,
            rules: _ctx.formDataRules,
            ref: "formComponent",
            onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Naziv",
                prop: "content.name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.formData.content.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.content.name) = $event)),
                    placeholder: "npr. Trčanje"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.content.fields, (item, index) => {
                return (_openBlock(), _createBlock(_component_el_row, {
                  gutter: 16,
                  type: "flex",
                  justify: "center",
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      xs: 11,
                      sm: 11,
                      md: 11,
                      lg: 11,
                      xl: 11
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "Dodatno polje",
                          prop: "content.fields"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: _ctx.formData.content.fields[index].name,
                              "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].name) = $event),
                              placeholder: "Upišite naziv",
                              onKeyup: ($event: any) => (_ctx.setProperty(index))
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, {
                      xs: 8,
                      sm: 8,
                      md: 8,
                      lg: 8,
                      xl: 8
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "Tip",
                          prop: "content.fields"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, {
                              modelValue: _ctx.formData.content.fields[index].type,
                              "onUpdate:modelValue": ($event: any) => ((_ctx.formData.content.fields[index].type) = $event),
                              placeholder: "Odaberite"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_option, {
                                  label: "Tekst",
                                  value: "text"
                                }),
                                _createVNode(_component_el_option, {
                                  label: "Tekst duži",
                                  value: "textarea"
                                }),
                                _createVNode(_component_el_option, {
                                  label: "Broj",
                                  value: "number"
                                }),
                                _createVNode(_component_el_option, {
                                  label: "Odabir Da/Ne",
                                  value: "select"
                                }),
                                _createVNode(_component_el_option, {
                                  label: "Raspon",
                                  value: "range"
                                }),
                                _createVNode(_component_el_option, {
                                  label: "Datum",
                                  value: "datepicker"
                                }),
                                _createVNode(_component_el_option, {
                                  label: "Vrijeme",
                                  value: "timepicker"
                                })
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, {
                      xs: 5,
                      sm: 5,
                      md: 5,
                      lg: 5,
                      xl: 5
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.sortDownField(index))
                            }, [
                              _createVNode(_component_Bottom)
                            ], 8, _hoisted_6)
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.sortUpField(index))
                            }, [
                              _createVNode(_component_Top)
                            ], 8, _hoisted_8)
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.removeField(index))
                            }, [
                              _createVNode(_component_CircleClose)
                            ], 8, _hoisted_10)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addField && _ctx.addField(...args)))
                }, [
                  _createVNode(_component_CirclePlus)
                ])
              ]),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "el-button--primary el-button--block",
                    "native-type": "submit",
                    loading: _ctx.isLoading,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateForm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Spremi ")
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "onSubmit"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}