
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {AppAdministratorDiagnosticsCategoriesListIndex} from "@/models/app/administrator/diagnostics/categories/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorDiagnosticsCategoriesListFormCreateIndexVue from "@/views/app/administrator/diagnostics/categories/list/form/create.vue";
import AppAdministratorDiagnosticsCategoriesListFormUpdateIndexVue from "@/views/app/administrator/diagnostics/categories/list/form/update.vue";
import AppAdministratorDiagnosticsCategoriesListFormRemoveIndexVue from "@/views/app/administrator/diagnostics/categories/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppAdministratorDiagnosticsCategoriesListFormCreateIndexVue,
    AppAdministratorDiagnosticsCategoriesListFormUpdateIndexVue,
    AppAdministratorDiagnosticsCategoriesListFormRemoveIndexVue,
  },
})
export default class AppAdministratorDiagnosticsCategoriesListIndexVue extends Vue {
  isLoading: boolean | null = false;
  moment: any = null;

  diagnosticCategories: Array<AppAdministratorDiagnosticsCategoriesListIndex> = [];

  async getDiagnosticCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/diagnostic-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.diagnosticCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    await this.getDiagnosticCategories();
  }
}
