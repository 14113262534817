
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorDiagnosticsNavigationVue from "@/components/administrator/diagnostics/navigation.vue";
import AppAdministratorDiagnosticsCategoriesListIndexVue from "@/views/app/administrator/diagnostics/categories/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorDiagnosticsNavigationVue,
    AppAdministratorDiagnosticsCategoriesListIndexVue,
  },
})
export default class AppAdministratorDiagnosticsCategoriesIndexVue extends Vue {}
